import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,
  isAffiliated: true,

  rivalName: "fatbet",
  casinoId: 333,
  GA_TRACKING: "G-KLSB0J84YQ",

  rivalChatGroupName: "FatBet",

  prettyName: "FatBet",
  contactEmail: "support@fatbetcasinos.com",
  docsEmail: "documents@fatbet.email",

  publicPromotions: [
    {id: 1, name: "400", state: "eligible", details: "welcome", imgPath: "assets/icons/welcome_promo.png"},
    {id: 2, name: "cashback", state: "eligible", details: "welcome", imgPath: "assets/icons/cashback_promo.png"},
    {id: 3, name: "raffle", state: "eligible", details: "weekly", imgPath: "assets/icons/raffle_promo.png"},
    {id: 4, name: "cashtravaganza", state: "eligible", details: "weekly", imgPath: "assets/icons/cashtra_promo.png"},
    {id: 5, name: "comppoints", state: "eligible", details: "weekly", imgPath: "assets/icons/comppoint_promo.png"}
  ],

  legalData: {
    withdrawalTime: 14,
    minDepositAmount: 5,
    affTerms: false
  },

  //SEO
  metaDescription: "Join FatBet Casino for premium online casino gaming with over 350 slot games, table games, and live dealer gambling. Get real cash bonuses, enroll in our VIP program, take no deposit bonus and secure payment transactions. Play FatBet online casino on mobile even with crypto!",
  logoName: "fatbet-online-casino-logo.png",
  logoAltText: "Official logo of FatBet Casino, in bold yellow and white text, presenting this online casino as one of the best-one would think-due to a wide selection of slots, table games, and exciting bonuses that one can enjoy while top security is provided."

};


